import request from '@/utils/request'

// 获取code
export function getCode(query) {
  // 使用urlencode对链接进行处理（ios无需进行urlencode）
  let u = navigator.userAgent;
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  if(isAndroid) {
     query.redirectUri = encodeURIComponent(query.redirectUri);
  }

  return request({
    url: '/open/union/1.0/token/code',
    method: 'get',
    headers: {'appId': '0001'},
    params: query
  })
}

// 获取backendToken
export function getBackendToken() {
   return request({
       url: '/open/union/1.0/token/backendToken',
       method: 'get'
       //headers: {'appId': appId}
     })
}

// 获取accessToken和openId
export function getAccessToken(query) {
    return request({
       url: '/open/union/1.0/token/accessToken',
       method: 'get',
       //headers: {'appId': appId},
       params: query
     })
}

// 获取url中"?"符后的字符串
export function getRedirectUri() {
  let url = window.location.search;
  let theRequest = new Object();
  if (url.indexOf("?") != -1) {
    let str = url.substr(1);
    let strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
  }

  let code = theRequest['code']; //undefined;
  let errmsg = theRequest['errmsg'];
  let errorcode = theRequest['errorcode'];

   // 通过函数decodeURIComponent解码去获取code
  if(errmsg === "undefined" && errorcode === "undefined" && code !== "undefined") {
    code = decodeURIComponent(code);
  }

  // 弹出错误提示信息
  return theRequest;
}

// 获取优惠券
export function getPhoneNumber(phone,mbrChannelInfo,id) {
    return request({
        url: '/coupon/acquire/download/'+phone+'/'+mbrChannelInfo+'/'+id,
        method: 'get'
    })
}

// 获取手机号
export function listPhone(token) {
    return request({
        url: '/coupon/acquire/getMobile/'+token,
        method: 'get'
    })
}

// 获取openId
export function getOpenId(code, scope) {
    return request({
        url: 'open/union/1.0/token/openId?code=' + code + '&scope=' + scope,
        method: 'get'
    })
}
